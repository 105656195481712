<!-- header start -->
<div class="detail-header">
  <div class="logo-inner">
    <a href="#">
      <img src="assets/images/logo1.svg" *ngIf="!IsMOSFilter" alt="Logo" width="192" height="42">
      <img src="assets/images/mos-selling-logo.png" *ngIf="IsMOSFilter" alt="Logo"  width="117" height="48">
    </a>
  </div>
  <div class="detail-header-right" *ngIf="!IsMOSFilter">
    <div class="currency-head"><img src="assets/images/dollar-currency.svg" alt="Dollar" width="30" height="28"></div>
    <div class="cs-btn"><a href="#"><img src="assets/images/cs-icon-head.svg" alt="Customer Support" width="25" height="22"> <span>24/7 Support</span></a></div>
  </div>
  <div class="detail-header-right"  *ngIf="IsMOSFilter">
    <div class="ms-brand">
      <div class="brand-icon" *ngIf="agentBrandImage!=''">
        <img src='{{agentBrandImage}}' alt="{{agentBrandName}}">
      </div>
      <span *ngIf="agentBrandImage!=''">{{agentBrandName}}</span>
    </div>
  </div>
</div>
<!-- header ends -->
